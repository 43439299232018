$(window).on("resize", function () {
  rotation();
});

$(".hamburger").on("click", function () {
  if (!$(this).hasClass("hamburger--active")) {
    $(this).addClass("hamburger--active");
    $(".nav").slideDown(400);
    $("body").css("overflow", "hidden");
    if ($(window).width() < 576) {
      $(".header__toolbar").css("display", "flex").hide().slideDown(300);
    }
  } else {
    $(this).removeClass("hamburger--active");
    $(".nav").slideUp(300);
    $("body").css("overflow", "");
    if ($(window).width() < 576) {
      $(".header__toolbar").css("display", "none");
    }
  }
});

$(".nav ul li").on("click", function () {
  console.log($(this).attr("data_id"));
  const id = $(this).attr("data_id");
  const nav = $(".nav");
  if (id !== undefined) {
    if (!$(this).hasClass("active")) {
      nav.find("li").removeClass("active");
      nav.find("ul li ul").slideUp(300);
      nav.removeClass();
      $(this).addClass("active");
      $(".nav__symbol").removeClass("active");
      nav.addClass("nav section-" + id);
      $(".nav__symbol[data-id='" + id + "']").addClass("active");
      $(this).children("ul").slideDown(300);
    } else {
      $(this).removeClass("active");
      $(this).children("ul").slideUp(300);
    }
  }
});

function rotation() {
  $(".nav").removeClass().addClass("nav section-1").fadeOut();
  $(".hamburger").removeClass("hamburger--active");
  if ($(window).width() > 576) {
    $(".header__toolbar").css("display", "flex");
  }
}


$(".header__toolbar-search-btn").on('click', function() {
  $('.header__search').slideToggle();
})
 
